import React, { useState } from "react";
import {
  Button,
  Col,
  colors,
  Csku,
  LabeledInput,
  Popup,
  Row,
  Text,
} from "@commonsku/styles";
import { BASE_ZINDEX } from "../../../popup-factory";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { closePopup } from "../../../actions/popup";
import { PopupHeaderWithCloseIcon } from "./LoadReportPopup";
import {
  createSavedReport,
  getSavedReportsState,
  updateSavedReport,
} from "../../../redux/savedReports";
import { formatDateFromString } from "../../../utils";
import { useReportContext } from "../report_context";
import { isEqual } from "lodash";

const StyledContainer = styled.div<{ isOnSavedReport: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .report-info {
    width: 100%;
    min-height: 74px;
    height: fit-content;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 16px;
    padding: 8px;
    color: ${colors.neutrals.darkest};
    background-color: ${({ isOnSavedReport }) =>
      isOnSavedReport ? "#e5f6f9" : "#fff"};

    p {
      margin: 0;
    }

    .report-name {
      font-weight: 600;
    }
  }
`;

const SaveReportPopup = () => {
  const dispatch = useDispatch();
  const { current_report, reports } = useSelector(getSavedReportsState);
  const {
    query,
    reportConfig: { type },
    fetchReport,
  } = useReportContext();

  const isOnSavedReport = !!current_report.id;
  const currentReportFullView = reports.find(
    (r) => r.report_id === current_report.id,
  );
  const savedReportFilters = currentReportFullView?.report_filters;

  const didReportFiltersChange = !isEqual(savedReportFilters, query);
  const [inputReportName, setInputReportName] = useState<string>(
    current_report.name || "",
  );
  const didReportNameChange = inputReportName.trim() !== current_report.name;
  const isAbleToSave =
    inputReportName.length > 0 &&
    (!isOnSavedReport || didReportNameChange || didReportFiltersChange);

  const lastUpdated = formatDateFromString(currentReportFullView?.date_created);

  const saveReport = async () => {
    if (isAbleToSave) {
      if (isOnSavedReport) {
        dispatch(
          updateSavedReport(current_report.id, inputReportName.trim(), query),
        );
        onPopupClose();
        fetchReport();
      } else {
        dispatch(createSavedReport(inputReportName.trim(), query, type));
      }
    }
  };

  const onPopupClose = () => dispatch(closePopup());

  const handleReportNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputReportName(e.target.value);
  };

  return (
    <Popup
      overlayZIndex={BASE_ZINDEX + 100}
      zIndex={BASE_ZINDEX + 200}
      style={{ width: 480, height: "fit-content" }}
      header={
        <PopupHeaderWithCloseIcon
          title="Save Report"
          onPopupClose={onPopupClose}
        />
      }
    >
      <StyledContainer isOnSavedReport={isOnSavedReport}>
        <LabeledInput
          label={"Report Name"}
          value={inputReportName}
          placeholder={"Type Here"}
          onChange={handleReportNameChange}
        />
        <Csku className="report-info">
          {isOnSavedReport && (
            <>
              <Text as={"p"}>
                Overwrite &nbsp;
                <Text as={"span"} className="report-name">
                  {current_report.name}
                </Text>
              </Text>
              <Text as={"p"}>Last updated {lastUpdated}</Text>
            </>
          )}
        </Csku>
        <Row>
          <Col xs={3}>
            <Button variant="secondary" onClick={onPopupClose} mr={24}>
              Cancel
            </Button>
          </Col>
          <Col xs={9}>
            <Button
              variant={isAbleToSave ? "primary" : "secondary"}
              disabled={!isAbleToSave}
              onClick={saveReport}
              style={{ width: "100%" }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </StyledContainer>
    </Popup>
  );
};

export default SaveReportPopup;
